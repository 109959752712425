@import "@arcgis/core/assets/esri/themes/light/main.css";

@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-zoom.css';
@import 'lightgallery/css/lg-video.css';
@import 'lightgallery/css/lg-thumbnail.css';
@import 'lightgallery/css/lg-pager.css';
@import 'lightgallery/css/lg-rotate.css';

/* *::-webkit-scrollbar {
    display: none;
  } */

/* Hide scrollbar for IE, Edge and Firefox */
/* * {
    -ms-overflow-style: none; 
    scrollbar-width: none;  
  } */

@font-face {
  font-family: somar;
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/somar/Somar-Light.otf");
}

@font-face {
  font-family: somar;
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/somar/Somar-Regular.otf");
}

@font-face {
  font-family: somar;
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/somar/Somar-Medium.otf");
}

@font-face {
  font-family: somar;
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/somar/Somar-SemiBold.otf");
}

@font-face {
  font-family: somar;
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/somar/Somar-Bold.otf");
}

@font-face {
  font-family: somar;
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/somar/Somar-ExtraBold.otf");
}

@font-face {
  font-family: SFArabic;
  src: url("./assets/fonts/SFArabic/SFArabic-Regular.ttf");
}

@font-face {
  font-family: SFArabicRound;
  src: url("./assets/fonts/SFArabic/SFArabicRounded-Regular.ttf");
}

:root {
  --base-font-size: 0.875rem;
  font-size: var(--base-font-size);
}

body {
  margin: 0;
  font-family: "SFArabic" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

* {
  font-family: "SFArabic" !important;
}

ion-modal#propertyModal {
  --width: fit-content;
  --min-width: 250px;
  --width: 90%;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
}

ion-modal#propertyModal h1 {
  margin: 20px 20px 10px 20px;
}

ion-modal#propertyModal ion-icon {
  margin-right: 6px;
  width: 48px;
  height: 48px;
  padding: 4px 0;
  color: #aaaaaa;
}

ion-modal#propertyModal .wrapper {
  margin-bottom: 10px;
}


/*  */

.my-custom-modal-css .modal-wrapper {
  height: 20%;
  top: 80%;
  position: absolute;
  display: block;
}

.esri-component.esri-attribution.esri-widget {
  display: none !important;
}

.input-error {
  border-color: red;
}

.toasterStyle {
  font-size: 20px;
}


.alert-input {
  font-size: 25px;
}


.action-sheet-button {
  font-size: 25px !important;
}

.item-select {
  font-size: 20px !important;
}

.alert-checkbox-label {
  font-size: 20px !important;
}

.alert-button {
  font-size: 20px !important;
}

.searchbar-input {
  font-size: 20px !important;
}

.icon-inner,
.ionicon,
svg {
  width: 95%
}


button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}


.nafazRandomClass {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
  text-align: center;
  background: #272eaf
}

/*///////////////////////////////////////////////////////////////////*/
.primary {
  color: #FFFF;
  background-color: #1433CB;
}

.item-interactive {
  --border-width: 0 !important;
  --inner-border-width: 0 !important;
  --show-full-highlight: 0 !important;
  --show-inset-highlight: 0 !important;

}

ion-item {
  --inner-padding-end: unset !important;
  --padding-start: unset !important;
}

ion-item.sc-ion-input-md-h:not(.item-label),
ion-item:not(.item-label) .sc-ion-input-md-h {
  --padding-start: unset !important
}


ion-toolbar {
  --min-height: 5px !important;
}

ion-input input {
  --padding-start: 15px !important;
}

ion-input.has-focus {
  --color: #0031c4 !important;
  border: 1px solid #0031c4 !important;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-weight: normal;
}

ion-item.item-has-focus ion-textarea {
  --color: #0031c4 !important;
  border: 1px solid #0031c4 !important;
}


ion-textarea textarea {
  max-width: 20em !important;
  overflow: auto;
}


ion-searchbar {
  --border-radius: 4px !important;

  input.searchbar-input {
    background: transparent !important;
    border: 1px solid #aeb5d6 !important;
  }

  ion-icon.searchbar-search-icon {
    color: #0031c4 !important;
  }
}

ion-searchbar.searchbar-has-focus {
  --color: #0031c4 !important;

  .searchbar-input {
    border: 1px solid #0031c4 !important;
  }


}

ion-modal {
  --border-radius: 10px !important;
  /* backdrop-filter: blur(5px) !important; */
}

input::file-selector-button {
  background-color: #0031c4;
  color: white;
  border: #0031c4;
  border-radius: 5px;
}

ion-popover ion-content ion-item {
  --color: #0031c4 !important;
  --detail-icon-opacity: 1;
}

ion-modal:has(.liftUpModal) {
  --height: auto;
} 

 .liftUpModal {
  width: auto;
  height: 42rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

