#div {
  display: flex;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: conic-gradient(#0031C4 var(--progress), rgba(0, 49, 196, 0.2) 0deg);
  font-size: 0;
}

#div::after {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 10px;
  border-radius: 50%;
  /* background: green; */
  /* font-size: 1rem; */
  text-align: center;
}

.lg-inner {
  display: flex;
}

.gallery-item {
  position: relative;
}

.gallery-item img,
.gallery-item video {
  display: block;
}

.watermark {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  font-size: 12px;
  pointer-events: none;
  opacity: 0.7;
}

.lg-watermark {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  font-size: 12px;
  pointer-events: none;
  opacity: 0.7;
}

.lg-thumb-outer {
  direction: ltr;
}