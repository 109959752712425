ion-modal#messageModal {
  --width: fit-content;
  --min-width: 250px;
  --width: 90%;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#messageModal h1 {
  margin: 20px 20px 10px 20px;
}

ion-modal#messageModal ion-icon {
  margin-right: 6px;
  width: 48px;
  height: 48px;
  padding: 4px 0;
  color: #aaaaaa;
}

ion-modal#messageModal .wrapper {
  margin-bottom: 10px;
}
