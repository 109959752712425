#div {
    display: flex;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: conic-gradient(#0031C4 var(--progress),  rgba(0, 49, 196, 0.2) 0deg);
    font-size: 0;
  }
  
 #div::after {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 10px;
    border-radius: 50%;
    /* background: green; */
    /* font-size: 1rem; */
    text-align: center;
  }



  .hiddenClass{
    visibility: hidden
  }


  .aTagStyle{
    font-size: 15px;
    color: #0031C4;
  }

  .strongStyle{
    font-size: 15px;
  }