.icoonn {
    position: relative;
}

.badge {
    position: absolute;
    top: -18px;
    right: -20px;
    background-color: #dc3545;
    color: white;
    border-radius: 50%;
    padding: 0px 6px;
    font-size: 15px;
    font-weight: bold;
}