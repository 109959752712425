.tracking-modals-container {
    position: relative;
    height: 100%;
  }
  
  .top-right-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 120px;
    /* height: 80px; */
    z-index: 10;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  
  .bottom-bar-modal {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    /* height: 70px; */
    z-index: 10;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  